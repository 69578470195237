import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import Image from "./assets/photos/6.webp";
import Image2 from './assets/photos/5.webp';
import img from './assets/herosection/1.webp'
export default function landingPage() {
  const PhoneNumber = {
    PhoneNo: "(888) 525-0579",
  };
  const HeroContent ={
    Spann: "If you are a senior or disabled person who wants to live independently, our medical alert system can give you the peace of mind you need. ",
    Heading: `Top <span>Medical Alert </span> Solutions 
          for Your Independence `,
    Para: `Personalized Matches to Keep  You Safe and Secure 24/7` , 
    Image: img,

  }
  const ContactText ={
    Heading: ` Safeguard Your </br> Health 
with Reliable </br>
Medical Alert Devices`
  }
  const HowWork = {
    Image: Image, 
  }
  const chooseUs = {
    Image: Image2, 
  }
  return (
    <div className="hp">
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber} />
      <HowItWorks HowWork={HowWork} PhoneNumber={PhoneNumber} />
      <WhyChooseUs chooseUs={chooseUs} PhoneNumber={PhoneNumber} />
      <Services PhoneNumber={PhoneNumber}/>
      <Contact ContactText={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
