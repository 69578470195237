import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import Image1 from "../assets/images/photos/about-1.jpg";

export default function HowItWorks({ PhoneNumber , HowWork }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="AboutUs">
      <div className="AboutUsContainer">
        <div className="AboutLeft">
          <img src={HowWork.Image} alt="" />
          {/* <div className="top-abs"></div> */}
          {/* <div className="bot-left-abs"> <br />
          <span></span></div> */}
          {/* <div className="bot-right-abs"></div> */}
        </div>
        <div className="AboutRight">
          <div >
            <div >
              <span >Our Services</span>
              <h2>Personalized Recommendations for Your Safety</h2>
            </div>
            <div >
              {/* <h6>
                Committed To Delivering High Quality Medical &amp; Diagnostics
                Services!
              </h6> */}
              <p>
              We simplify the process by offering expert guidance to match you with the most reliable and suitable options. Our goal is to ensure you find a system that fits your lifestyle and provides the protection you need.
              </p>
              {/* <ul >
                <li>Ambulance Services</li>
                <li>Oxizen on Wheel</li>
                <li>Pharmacy on Clinic</li>
                <li>On duty Doctors</li>
                <li>24/7 Medical Emergency</li>
              </ul> */}
            </div>
            <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW: (888) 525-0579</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
