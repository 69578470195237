import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import BgImage from '../assets/images/photos/shape-1.png'
import Image1 from '../assets/images/photos/banner-img-1.png'
import Hand from '../assets/icon/hand.png'
export default function HeroSection({ PhoneNumber , HeroContent }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="HeroSection">
      <div className="hero_container">
        <div className="hero-left">
          <span>{HeroContent.Span}</span>
          <h2 dangerouslySetInnerHTML={{ __html: HeroContent.Heading }} />
          <p>{HeroContent.Para}</p>
          <div className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
              CALL NOW:{PhoneNumber.PhoneNo}
            </a>
          </div>
        </div>
        <div className="hero-right">
          <img className="animated-image" src={HeroContent.Image} alt="" />

        </div>
      </div>
      {/* <div className="hand-img">
        <img src={Hand} alt="" />
      </div> */}
     
    </div>
  );
}
