import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import ArrowIcon from '../assets/icon/shape-17.png';
import CImage from '../assets/photos/1.webp';
export default function Contact({ PhoneNumber, ContactText }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="Contact">
      <div className="contactFlex">
        <div className="left">
          <img src={CImage} alt="" />
        </div>

        <div className="right">
          <h2 dangerouslySetInnerHTML={{ __html: ContactText.Heading }} />
          <div className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
              CALL NOW:{PhoneNumber.PhoneNo}
            </a>
          </div>

        </div>
      </div>
      {/* <div className="abs-img">
        <img src={ArrowIcon} alt="" />
      </div> */}
    </div>
  );
}
