import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div className="sec1">
      <section>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <h2>DISCLAIMER</h2>
                  </div>
                </div>
                <div>
                  <div>
                    <p>
                      The Site cannot and does not contain Medical Alert device
                      service advice. The Medical Alert device service
                      information is provided for general informational and
                      educational purposes only and is not a substitute for
                      professional advice. Accordingly, before taking any
                      actions based upon such information, we encourage you to
                      consult with the appropriate professionals. We do not
                      provide any kind of Medical Alert device service advice.
                      THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE
                      SITE IS SOLELY AT YOUR OWN RISK.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="cnt">
       
        <section>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <h4>PROFESSIONAL DISCLAIMER</h4>
                  </div>
                </div>
                <div>
                  <div>
                    <p>
                      The Site cannot and does not contain Medical Alert device
                      service advice. The Medical Alert device service
                      information is provided for general informational and
                      educational purposes only and is not a substitute for
                      professional advice. Accordingly, before taking any
                      actions based upon such information, we encourage you to
                      consult with the appropriate professionals. We do not
                      provide any kind of Medical Alert device service advice.
                      THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE
                      SITE IS SOLELY AT YOUR OWN RISK.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>TCPA DISCLAIMER</h4>
                  </div>
                </div>
                <div>
                  <div>
                    <p>
                      Notwithstanding any current or prior election to opt in or
                      opt out of receiving telemarketing calls, or SMS messages
                      (text messages) from our representatives, agents,
                      affiliates, you expressly consent to be contacted by FIRST
                      RATE MEDICAL ALERT, our agents, representatives,
                      affiliates, or anyone calling on our behalf. This is for
                      any purposes relating to your request for Medical Alert
                      device service, at any telephone number, or physical or
                      electronic address you provide or at which you may be
                      reached. You agree we may contact you in any way,
                      including SMS messages (text messages), calls using
                      prerecorded messages or artificial voice, and calls and
                      messages delivered using auto telephone dialing system or
                      an automatic texting system.
                    </p>
                    <p>
                      Automated messages may be played when the telephone is
                      answered whether by you or someone else. In the event that
                      an agent or representative of FIRST RATE MEDICAL ALERT
                      calls, he or she may also leave a message on your
                      answering machine, voice mail, or send one via text. You
                      certify that the telephone numbers that you have provided,
                      are in fact your contact numbers, and you agree to receive
                      calls at each phone number you have provided FIRST RATE
                      MEDICAL ALERT. You agree to promptly alert FIRST RATE
                      MEDICAL ALERT in the event that you stop using a
                      particular phone number. Your cell/mobile telephone
                      provider will charge you according to the type of account
                      you carry with those companies. You may opt out of any
                      future contact via text message by replying anytime with
                      “STOP”. You agree that FIRST RATE MEDICAL ALERT may
                      contact you by email, using any email address you have
                      provided or that you provide in the future. You may opt
                      out of any future contact via email message by replying
                      anytime with “UNSUBSCRIBE”. FIRST RATE MEDICAL ALERT may
                      listen to and/or record calls between you and any
                      representative without notice, as permitted by applicable
                      laws. For example, we listen to calls to monitor for
                      quality purposes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
