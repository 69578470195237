import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_dl_lps";
import WhyChooseUs from "../components/WhyChooseUs";
import Contact from "../components/Contact";
import img from '.././assets/herosection/2.webp'
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_dl_lps.js");
  const PhoneNumber = {
    PhoneNo: "(888) 525-0579",
  };
  const HeroContent = {
    Span: "",
    Heading: `Never <span>Worry </span>  
About Emergencies Again `,
    Para: `Connect with Trusted Medical Alert Providers `,
    Image: img,
  };
  const ContactText = {
    Heading: ` Ensure Your Safety </br>
with Trustworthy </br>
Medical Alert Systems`,
  };
  return (
    <div  className="hp">
        <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Contact ContactText={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
