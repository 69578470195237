import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_bng_lps";
import WhyChooseUs from "../components/WhyChooseUs";
import Contact from "../components/Contact";
import img from '.././assets/herosection/4.webp'
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_bng_lps.js");
  const PhoneNumber = {
    PhoneNo: "(866) 607-4586",
  };
  const HeroContent = {
    Span: "",
    Heading: `Stay <span> Protected </span>   with </br>
Top-Rated   <br />
Medical Alert Systems `,
    Para: `Connect with Leading Providers 
to Secure Your Future `,
Image: img,
  };
  const ContactText = {
    Heading:
      `Keep Yourself Safe </br>
with Reliable </br>
Medical Alert Devices`,
  };
  return (
    <div className="hp">
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Contact ContactText={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
