import React, { useEffect } from 'react';

const ExternalScriptsLoader = () => {
  useEffect(() => {
    console.log('Component mounted');

    // Function to load the Ringba script
    const loadRingba = () => {
      console.log('Loading Ringba script');
      const script = document.createElement('script');
      script.id = '__ringba_script__';
      script.type = 'text/javascript';
      script.async = true;
      script.src = '//b-js.ringba.com/CA16d6edb62b43497ca84cc98496e72bea';
      document.body.append(script);
      console.log('Ringba script added to the document');
    };

    // Function to load the Universal script
    const loadScript = () => {
      console.log('Loading Universal script');
      const script = document.createElement('script');
      script.id = '__universal_script__';
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://track.imgtrx3.com/uniclick.js?attribution=lastpaid&cookiedomain=firstratemedicalalert.com&cookieduration=30&defaultcampaignid=632e3350d274bf0001c1d71e&regviewonce=false';
      document.body.append(script);
      console.log('Universal script added to the document');
    };

    // Function to check for a specific cookie
    const checkCookie = () => {
      console.log('Checking Cookie');
      const myInterval = setInterval(() => {
        const myCookie = sessionStorage.getItem('rtkclickid');
        if (myCookie && window._rgba_tags) {
          console.log('COOKIE RECEIVED', myCookie);
          window._rgba_tags = window._rgba_tags || [];
          window._rgba_tags.push({ clickID: myCookie });
          clearInterval(myInterval);
          console.log('Cookie found and pushed to _rgba_tags');
        } else {
          console.log('Cookie is not there');
        }
      }, 2000);
    };

    // Execute the functions directly
    window._rgba_tags = undefined;
    loadRingba();
    loadScript();
    checkCookie();

    // Cleanup on component unmount
    return () => {
      console.log('Component unmounted, cleaning up');
    };
  }, []);

  return null;
};

export default ExternalScriptsLoader;
