import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_fb_lps";
import WhyChooseUs from "../components/WhyChooseUs";
import Contact from "../components/Contact";
import img from '.././assets/herosection/3.webp'
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_fb_lps.js");
  const PhoneNumber = {
    PhoneNo: "(877) 651-6161",
  };
  const HeroContent = {
    Span: "",
    Heading: ` 
          For Your <span>Independence</span> </br> Find the Perfect </br>
Medical Alert System`,
    Para: `Expert Guidance to Help You 
Choose the Best Option`,
Image: img,
  };
  const ContactText = {
    Heading:
      `Protect Your Health </br>
with Dependable </br>
Medical Alert Devices`,
  };
  return (
    <div>
        <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Contact ContactText={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
